import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Software betrouwbaar aanbieden in productie – Stap 4A van onze aanpak"
        description="Software betrouwbaar aanbieden in productie – Stap 4A van onze aanpak"
        ogImage="service.png"
        translatedPagePath="/en/approach/service/">
        <Link
            to="/nl/werkwijze/productrealisatie/"
            className="body__nav body__nav--left"
            title="Aanpakstap 3. Realisatie">
            Vorige stap
        </Link>

        <Link
            to="/nl/werkwijze/continue-doorontwikkeling/"
            className="body__nav body__nav--right"
            title="Aanpakstap 4B. Doorontwikkelen">
            Volgende stap
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Software <br /> betrouwbaar <br /> aanbieden
                        </h2>
                        <div className="display__number">4a</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Voor een gelanceerd software&shy;product is het essen&shy;tieel een continue, veilige en{' '}
                            juiste werking te waar&shy;borgen&mdash;dit gebeurt niet vanzelf. Daarom stemmen we samen af{' '}
                            welk service- en onderhouds&shy;niveau het beste past bij het product.
                        </p>
                        <p>
                            We zijn beschikbaar voor het leveren van{' '}
                            <span className="highlight">onder&shy;steuning</span> aan de opdrachtgever en het oplossen{' '}
                            van <span className="highlight">acute problemen</span>. Daarnaast houden we de{' '}
                            applicatie-infrastructuur <span className="highlight">up-to-date</span>. Deze afspraken{' '}
                            leggen we vast in een service&shy;overeenkomst.
                        </p>
                        <p>
                            Voor bedrijfs&shy;kritische software&shy;producten bieden we een SLA aan, met gegarandeerde{' '}
                            uptime en gegevens&shy;integriteit. We verzorgen{' '}
                            <span className="highlight">continue monitoring</span>, en{' '}
                            <span className="highlight">grijpen proactief in</span> bij problemen.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Doorlopend onderhoud</li>
                            <li className="ion-md-arrow-dropright">Proactief ingrijpen</li>
                            <li className="ion-md-arrow-dropright">Continu monitoren</li>
                            <li className="ion-md-arrow-dropright">Uptime gegarandeerd</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/nl/werkwijze/continue-doorontwikkeling/"
                            name="Tegelijkertijd: doorontwikkelen"
                            title="Aanpakstap 4B. Doorontwikkelen"
                        />
                    </div>
                </div>
            </article>

            <article className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Onze <br /> infra&shy;structurele <br /> expertise
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Het betrouwbaar aanbieden van software&shy;producten hangt samen met de{' '}
                            infra&shy;structuur&shy;technieken waarvoor we kiezen.
                        </p>
                        <p>
                            Met onze brede ervaring in het realiseren van verschillende software&shy;producten,{' '}
                            gebruiken we de juiste infra&shy;structuur&shy;techniek voor jouw software&shy;product. Zo{' '}
                            kijken we naar de specifieke use-case en maken een wel&shy;overwogen keuze tussen{' '}
                            server&shy;less, cloud native, platform-as-a-service of klassieke virtualisatie.{' '}
                        </p>
                        <p>
                            Secundaire infrastructuur componenten sluiten we juist aan. Denk hierbij aan diensten voor{' '}
                            transactionele e-mail, het opslaan van bestanden of het afhandelen real time communicatie.{' '}
                            Software&shy;producten met een SLA houden we met de juiste monitoring tools constant in de{' '}
                            gaten.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />
                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo AWS" fluid={data.aws.childImageSharp.fluid} />
                                <span>Amazon Web Services</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Google cloud platform" fluid={data.googleCloud.childImageSharp.fluid} />
                                <span>Google Cloud Platform</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Digital Ocean" fluid={data.digitalocean.childImageSharp.fluid} />
                                <span>Digital Ocean</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Heroku" fluid={data.heroku.childImageSharp.fluid} />
                                <span>Heroku</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo AWS Lambda" fluid={data.awsLambda.childImageSharp.fluid} />
                                <span>AWS Lambda</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Firebase" fluid={data.firebase.childImageSharp.fluid} />
                                <span>Firebase</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Postmark" fluid={data.postmark.childImageSharp.fluid} />
                                <span>Postmark</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Solarwinds" fluid={data.solarwinds.childImageSharp.fluid} />
                                <span>Solarwinds</span>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        aws: file(relativePath: {eq: "logos/aws.png"}) {
            ...logoImage
        }
        googleCloud: file(relativePath: {eq: "logos/google-cloud.png"}) {
            ...logoImage
        }
        digitalocean: file(relativePath: {eq: "logos/digitalocean.png"}) {
            ...logoImage
        }
        heroku: file(relativePath: {eq: "logos/heroku.png"}) {
            ...logoImage
        }
        awsLambda: file(relativePath: {eq: "logos/aws-lambda.png"}) {
            ...logoImage
        }
        firebase: file(relativePath: {eq: "logos/firebase.png"}) {
            ...logoImage
        }
        postmark: file(relativePath: {eq: "logos/postmark.png"}) {
            ...logoImage
        }
        solarwinds: file(relativePath: {eq: "logos/solarwinds.png"}) {
            ...logoImage
        }
    }
`;
